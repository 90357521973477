<template>
  <v-container>
    <h1 class="display-1 ma-6">Отдел бухгалтерии Полисервис</h1>
    <v-row dense>
      <v-col
        v-for="(item, index) in items"
        :key="index"
        xs="12"
        sm="6"
        md="4"
      >
        <v-card
          :to="`${$route.path}/${item.url}`"
          hover
          height="100%"
        >
          <div class="d-flex flex-column justify-space-between fill-height">
            <v-card-title>
              {{item.title}}
            </v-card-title>
            <div class="py-2 icon--opacity">
              <v-img
                :src="item.img"
                height="100px"
                contain
              >
              </v-img>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'BookkeepingDepFormList',
    data: () => ({
      items: [
        {
          title: 'Выдача ДС под отчет',
          url: 'issuance-of-funds',
          img: './img/payment-method.svg'
        },
        {
          title: 'Компенсация расходов',
          url: 'compensation',
          img: './img/compensation.svg'
        },
        {
          title: 'Оформление командировки',
          url: 'business-trip',
          img: './img/business-trip.svg'
        },
        {
          title: 'Оформление отсутствия',
          url: 'absent',
          img: './img/house.svg'
        },
        {
          title: 'Оформление отпуска',
          url: 'vacation',
          img: './img/sunbed.svg'
        },
        {
          title: 'Оплата счетов',
          url: 'payment',
          img: './img/bill.svg'
        },
        {
          title: 'Оформление сотрудника',
          url: 'new-employee',
          img: './img/worker.svg'
        }
      ]
    })
  }
</script>
